// GlobalModal.js
import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import {setMessage} from 'src/store/alertMessage/actions'
import {addMessage} from '../modules/messages/message-list/core/_requests'

import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {getParty} from '../modules/customer/customers-list/core/_requests'
import {useQueryResponse} from '../modules/report/customerledger/customerledgers-list/core/QueryResponseProvider'
import {removeShortCut, setShortCut} from 'src/store/shortCut/actions'
import { startReload } from 'src/store/ReloadNotification/actions'

const AddMessage = ({isOpen, onClose}: any) => {
  const {refetch} = useQueryResponse()
  const [loading, setLoading] = useState<any>(false)
  const [senderCustomerList, setSenderCustomerList] = useState<any>([])
  const [receiverCustomerList, setReceiverCustomerList] = useState<any>([])
  const [selectedSenderCustomerId, setSelectedSenderCustomerId] = useState(null)
  const [selectedReceiverCustomerId, setSelectedReceiverCustomerId] = useState(null)
  const [initialCustomerList, setInitialCustomerList] = useState([])
  const dispatch = useDispatch()
  const intl = useIntl()
  const redirectList = useSelector((state: any) => state.commonList.redirectList)
  const editMessageSchema = Yup.object().shape({
    senderPartyId: Yup.string().required(`${intl.formatMessage({id: 'DEBIT.PARTY.IS.REQUIRED'})}`),
    receiverPartyId: Yup.string().required(
      `${intl.formatMessage({id: 'CREDIT.PARTY.IS.REQUIRED'})}`
    ),
    amount: Yup.number()
      .required(`${intl.formatMessage({id: 'AMOUNT.IS.REQUIRED'})}`)
      .min(1, 'Amount must be at least 1')

      .typeError('Amount must be a number'),

    narration: Yup.string(),
  })

  const MessageData = {
    senderPartyId: '',
    receiverPartyId: '',
    amount: '',
    narration: '',
  }

  const formik = useFormik({
    initialValues: MessageData,
    validationSchema: editMessageSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        setLoading(true)
        await addMessage(values)
          .then((data: any) => {
            if (data?.statusCode === 200 || data?.responseStatus == 200) {
              dispatch(setMessage('success', data?.message ?? data?.responseMessage))
              refetch()
              onClose()
              resetForm()
              setSelectedSenderCustomerId(null)
              setSelectedReceiverCustomerId(null)
              dispatch(setShortCut('Add Message', 'reloadAddMessage'))
              dispatch(startReload('Add Message', 'reload'))
              setTimeout(() => {
                dispatch(removeShortCut())
              setLoading(false)
              }, 2000)
            } else {
              dispatch(
                setMessage(
                  'error',
                  data?.responseData?.msg ? data?.responseData?.msg : data?.responseMessage
                )
              )
            }
            // setSubmitting(false)
          })
          .catch((error: any) => {
            if (error?.response?.data?.responseData?.msg) {
              dispatch(setMessage('error', error?.response?.data?.responseData?.msg))
            } else if (error?.response?.data?.responseMessage) {
              dispatch(setMessage('error', error?.response?.data?.responseMessage))
            } else {
              dispatch(setMessage('error', error?.message))
            }
            // setSubmitting(false)
            setLoading(false)
          })
      } catch (error) {
        setLoading(false)
      } 
    },
  })

  const getPartyList = async (search: any) => {
    try {
      let {responseStatus, responseData} = (await getParty(search)) as any
      if (responseStatus == 200 && responseData && responseData.length) {
        let customers = responseData.map((x: any) => {
          return {
            label: x.party_name,
            value: x.uuid,
            ...x,
          }
        })
        customers = [{value: null, label: 'Select..'}, ...customers]

        setInitialCustomerList(customers)
        setSenderCustomerList(customers)
        setReceiverCustomerList(customers)
      } else {
        setInitialCustomerList([])
        setSenderCustomerList([])
        setReceiverCustomerList([])
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
        ? err.responseMessage
        : ('Something went wrong' as any)
      dispatch(setMessage('error', message))
    }
  }

  const handleSenderCustomerChange = (selectedOption: any) => {
    setSelectedSenderCustomerId(selectedOption ? selectedOption.value : null)
    formik.setFieldValue('senderPartyId', selectedOption ? selectedOption.value : '')
  }

  const handleReceiverCustomerChange = (selectedOption: any) => {
    setSelectedReceiverCustomerId(selectedOption ? selectedOption.value : null)
    formik.setFieldValue('receiverPartyId', selectedOption ? selectedOption.value : '')
  }
  // useEffect(() => {
  //   if (redirectList.length > 0) {
  //     setSelectedSenderCustomerId(redirectList)
  //     formik.setFieldValue('senderPartyId', redirectList)
  //   }
  // }, [redirectList])

  const filteredSenderCustomerList = senderCustomerList.filter(
    (x: any) => x.value !== selectedReceiverCustomerId
  )
  const filteredReceiverCustomerList = receiverCustomerList.filter(
    (x: any) => x.value !== selectedSenderCustomerId
  )
  useEffect(() => {
    getPartyList('')
  }, [])

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <h2>Add Message</h2>
      </Modal.Header>
      <Modal.Body>
        <form
          id='kt_modal_add_message_form'
          className='form'
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Row className='mb-8'>
            <Col lg={6}>
              <label className='required fw-bold fs-6 mb-2'>DEBIT PARTY</label>
              <Select
                options={filteredSenderCustomerList}
                className='custom_select mb-3'
                placeholder={intl.formatMessage({id: 'SELECT'})}
                onChange={handleSenderCustomerChange}
                value={filteredSenderCustomerList.find(
                  (option: any) => option.value === selectedSenderCustomerId
                )}
              />
              {formik.touched.senderPartyId && formik.errors.senderPartyId && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.senderPartyId as any}</span>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <label className='required fw fs-6 mb-2'>
                {/* <FormattedMessage id='DEBIT.PARTY' /> */}CREDIT PARTY
              </label>
              <Select
                options={filteredReceiverCustomerList}
                className='custom_select mb-3'
                placeholder={intl.formatMessage({id: 'SELECT'})}
                onChange={handleReceiverCustomerChange}
                value={filteredReceiverCustomerList.find(
                  (option: any) => option.value === selectedReceiverCustomerId
                )}
              />
              {formik.touched.receiverPartyId && formik.errors.receiverPartyId && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.receiverPartyId as any}</span>
                </div>
              )}
            </Col>
          </Row>

          <Row className='mb-8'>
            <Col lg={6}>
              <label className='required fw fs-6 mb-2'>
                {/* <FormattedMessage id='POINT' /> */} AMOUNT
              </label>
              <input
                placeholder={intl.formatMessage({id: 'POINT'})}
                type='text'
                name='receiver_name'
                className='form-control form-control-solid mb-2 mb-lg-0'
                onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  const pastedValue = e.clipboardData.getData('text');
    
                  let cleanValue = pastedValue.replace(/[^0-9]/g, '');
            
                  if (cleanValue.length > 12) {
                    cleanValue = cleanValue.slice(0, 12);
                  }

                  formik.setFieldValue('amount', cleanValue);
                }}
                onChange={(e: any) => {
                 
                  let value = e.target.value
                  if (value.length > 12) {
                    value = value.slice(0, 12);
                }
                  if (/^\d*$/.test(value)) {
                    formik.setFieldValue('amount', value)
                  }
                }}
                value={formik?.values?.amount}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.amount as any}</span>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <label className=' fw fs-6 mb-2'>
                {/* <FormattedMessage id='NARRATION' /> */}
                REMARKS
              </label>
              <textarea
                placeholder={intl.formatMessage({id: 'NARRATION'})}
                name='Narration'
                maxLength={200}
                rows={3}
                className='form-control form-control-solid mb-3'
                required
                onChange={(e: any) => {
                  const uppercaseValue = e.target.value.toUpperCase()
                  formik.setFieldValue('narration', uppercaseValue.trimStart())
                }}
                value={formik?.values?.narration}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && !loading) {
                    e.preventDefault(); // Prevent default newline behavior
                    formik.handleSubmit(); // Submit the form
                  }
                }}
              />
              {formik.touched.narration && formik.errors.narration && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.narration as any}</span>
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-5'></Row>

          <div className='text-end pe-4 mb-6 pt-5 '>
            <button type='submit' className=' btn me-3 text-white mb-3 mb-md-0' disabled={loading}>
              <span className='indicator-label'>
                {loading ? 'Please Wait...' : intl.formatMessage({id: 'SUBMIT'})}
              </span>
            </button>

            <button type='reset' onClick={onClose} className='btn btn-light me-3 mb-3 mb-md-0'>
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddMessage
